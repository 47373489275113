import * as React from 'react';

import styled from 'styled-components';
import { Body } from '@allenai/varnish/components';
import * as icon from '@allenai/varnish/components/ai2/projects/icon';

export default class About extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <Body>
                    <h3>The model</h3>
                    <p>The RuleTaker model reads in a new
                    rulebase of natural language facts and rules, and decides the truth value of
                    new statements under closed world (and negation as failure) assumptions.</p>
                    <p>See our
                    paper <a href="https://arxiv.org/abs/2002.05867"><i>"Transformers as Soft Reasoners over Language"</i></a> for details.</p>
                    <h3>The dataset</h3>
                    <p>The model was trained on a dataset of synthetic language rules, augmented with some crowdsourced data.
                    Download the dataset from <a href="https://allenai.org/data/ruletaker">here</a>.
                    </p>
                    <p>
                    The model in this demo is trained starting from
                    a <a href="https://github.com/pytorch/fairseq/tree/master/examples/roberta">RoBERTa-large</a> model, first fine-tuned
                     on the <a href="https://www.cs.cmu.edu/~glai1/data/race/">RACE</a> dataset, and then on the "depth3-ext-NatLang"
                     training set.
                     </p>
                     <p>
                     A project from the <a href="https://allenai.org/aristo/"><CenterAlign><icon.Aristo width="22" height="22"/></CenterAlign>
                     Aristo Team</a>.
                     </p>

                </Body>
            </React.Fragment>
        );
    }
}

const CenterAlign = styled.span`
    vertical-align: middle;
`;
